import { Style, Fill, Stroke, Circle} from 'ol/style'

const defaultStyle = {
    fillColor: 'rgba(0, 0, 255, 0.1)',
    strokeColor: 'rgba(0, 0, 255, 0.5)',
    strokeWidth: 2
}

const getLegendDataFromLayers = layers => {
    return layers.sort((a, b) => a.get('name').localeCompare(b.get('name'))).map(layer => {
        const layerFeatures = layer.getSource().getFeatures()
        const geometryType = (layerFeatures && layerFeatures.length) ? layerFeatures[0].getGeometry().getType() : null
        const style = JSON.parse(layer.get('style'))
        const layerName = layer.get('name')
        return {
            layerName,
            geometryType,
            fillColor: style.fillColor ? style.fillColor : null,
            strokeColor: style.strokeColor ? style.strokeColor : null,
            visible: true
        }
    })
}

const getFeatureValue = (feature, fieldName) => feature.get(fieldName) ? feature.get(fieldName) : defaultStyle[fieldName]

const selectedStyle = new Style({
    image: new Circle({
        radius: 6,
        fill: new Fill({
            color: '#ADD8E6'
        }),
        stroke: new Stroke({
            color: 'white'
        })
    }),
    zIndex: 4
})

const renderStyles = {}

const getOrCreateRenderStyles = (feature, layerName, renderStyles) => {
    if (typeof renderStyles[layerName] === 'undefined') {
        const featureType = feature.getGeometry().getType()
        const fillColor = getFeatureValue(feature, 'fillColor')
        const strokeColor = getFeatureValue(feature, 'strokeColor')
        const strokeWidth = getFeatureValue(feature, 'strokeWidth')
        switch (featureType) {
            case "Polygon":
                renderStyles[layerName] = new Style({
                    fill: new Fill({
                        color: fillColor
                    }),
                    stroke: new Stroke({
                        color: strokeColor,
                        width: strokeWidth
                    })
                })
                break
            case "Point":
                renderStyles[layerName] = new Style({
                    image: new Circle({
                        radius: 3,
                        fill: new Fill({
                            color: fillColor
                        }),
                        stroke: new Stroke({
                            color: strokeColor, 
                            width: strokeWidth
                        })
                    })
                })
                break
            default:
                renderStyles[layerName] = undefined
        }
    }
    return renderStyles[layerName]
    
}

const featureMapStyleFunction = (layerName) => (feature) => {
    const selected = feature.get('selected')

    if (selected) {
        return selectedStyle
    }
    const renderStyle = getOrCreateRenderStyles(feature, layerName, renderStyles)
    return renderStyle
}

export { featureMapStyleFunction, getLegendDataFromLayers }